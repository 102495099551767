import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#06283D');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [rightMenu, setRightMenu] = useState(false);
  const [isLoged,setIsLoged]=useState(false);
  const [isClicked, setIsClicked] = useState(initialState);
  const [mobile,setMobile]=useState(false);
  const [smallDisplay,setSmallDisplay]=useState(false);
  const [loginDialogue,setLoginDialogue]=useState(false);
  const [places,setPlaces]=useState([]);
  const [currentScreenPlayerOptions,setCurrentScreenPlayerOptions]=useState(null);
  const [pendingCampaigns,setPendingCampaigns]=useState([]);
  const [activeCampaigns,setActiveCampaigns]=useState([]);
  const [archivedCampaigns,setArchivedCampaigns]=useState([]);
  const [userAccount,setUserAccount]=useState(null);
  const [transactions,setTransactions]=useState(null);
  const [userInfo,setUserInfo]=useState(null);
  const [userId,setUserId]=useState(null);
  const [campaignProgress, setCampaignProgress]=useState(0);
  const [previewedCampaign,setPreviewedCampaign]=useState(null);
  const [campaignVideoDuration, setCampaignVideoDuration]=useState(null);
  // "Restaurants",
  // "Shopping Malls",
  // "Fitness Centers",
  // "Carre Fou"
  const [countries, setCountries] = useState([
    "Cameroon",
    // "Nigeria"
  ]);
  const [paymentMethods,setPaymentMethods]=useState([
    {
      creditCard:['Cameroon','Nigeria'],
      momo:['Cameroon']
    }
  ])
  const [countryMetaData,setCountryMetaData]=useState([
    {
      country:'Cameroon',
      cities:['Douala','Buea','Yaounde','Edea'],
      currency:'FCFA',
    }
  ])
  const [currentLauchedCampaign, setCurrentLauchedCampaign]=useState({
    name:null,
    country:null,
    states:[],
    cities:[],
    places:[],
    duration:null,
    description:null,
    suggestedScreens:[],
    selectedScreens:[],
    price:0,
    file:null,
    fileType:null,
    fileSize:null,
    fileName:null,
    previewUrl:null,
    mediaType:null,
    showPreview:false,
    videoDuration:null,
  });
  const [campaignFile,setCampaignFile]=useState(null);
  const [allScreens,setAllScreens]=useState(null);
  // {
  //   id:4,
  //   name:'ALPHA & BETA',
  //   city:'Yaounde',
  //   location:'ALPHA & BETA',
  //   price:20000,
  //   place:'Cloth manufacturing',
  //   description:'A cloth manufacturing company',
  //   population:100,
  //   country:'Cameroon',
  //   images:['https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  //   'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  //   'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  //   'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',],
  //   longitude:33.5,
  //   lattitude:35.6,
  //   currency:'FCFA',
  // }
  const [suggestedScreens,setSuggestedScreens]=useState(null);
  const [selectedScreens,setSelectedScreens]=useState(null);
  //email address of current user who is signing up
  const [user,setUser]= useState(null);
  const setCurrentUser=(userInfo)=>{
      setUser(userInfo);
      localStorage.setItem('user',JSON.stringify(userInfo));
  }
  ////
  const setMode = (e) => {
    setCurrentMode(e.target.value); 
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{previewedCampaign,setPreviewedCampaign,transactions,setTransactions,archivedCampaigns,setArchivedCampaigns,activeCampaigns,setActiveCampaigns,pendingCampaigns,setPendingCampaigns,userId,setUserId,userInfo,setUserInfo,campaignProgress, setCampaignProgress,campaignFile,setCampaignFile,userAccount,setUserAccount,currentScreenPlayerOptions,setCurrentScreenPlayerOptions,countries, setCountries,selectedScreens,setSelectedScreens,suggestedScreens,setSuggestedScreens,countryMetaData,setCountryMetaData,places,setPlaces,allScreens,setAllScreens,currentLauchedCampaign, setCurrentLauchedCampaign,user,setCurrentUser,mobile,smallDisplay,setSmallDisplay,loginDialogue,setLoginDialogue,rightMenu,setRightMenu,setMobile, currentColor,isLoged,setIsLoged, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);