import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {colorCodes} from '../data/dummy';
//
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//
import {NavLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import BackdropLoader from './BackdropLoader';
//
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LoginDialogue(props) {
  const {setCurrentUser,setIsLoged,setUserInfo}= useStateContext();
  const [email,setEmail]= React.useState(null);
  const [password,setPassword]= React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate=useNavigate();
  const [error,setError]= React.useState(null);
  const [emailVerifyLink,setEmailVerifyLink]= React.useState(true);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  async function signIn() {
    if(email===null||email===""){
      setError({
        code:1,
        message:"E-mail Address Required!"
      })
    }else if(password===null||password===""){
      setError({
        code:2,
        message:"Password Required!"
      })
    }else{
      setError(null);
      toggleBackdrop(1);
      try {
          const user = await Auth.signIn(email, password);
          //store user in state
          setCurrentUser(user);
          setUserInfo(user);
          setIsLoged(true);
          navigate('/');
          console.log(user);
      } catch (err) {
        toggleBackdrop(0)
        console.log(err.name);
        if(err.name === 'UserNotConfirmedException'){
          setEmailVerifyLink(true);
          //pass user email to store
          setCurrentUser({
            username:email
          });
        }
        setError({
          code:2,
          message:err.message
        })
        
      }
    }
    
}
/////custom backdrop loader
const [showBackdrop,setShowBackdrop]= React.useState(false);
const toggleBackdrop=(value)=>{
  switch (value) {
    case 1:
      setShowBackdrop(true);
    break;
    case 0:
      setShowBackdrop(false);
    break;
  
    default:
      setShowBackdrop(!showBackdrop);
      break;
  }
  
}
  return (
    <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.secondary}}>DIGIT-AD SIGN IN</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className='text-center'>
              <div className='w-full'>
                  <TextField
                  required
                  id="outlined-required"
                  label="E-mail Address"
                  defaultValue=""
                  type="email"
                  autoFocus
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                   {
               error&& (error.code===1)?<Alert severity="error">{error.message}</Alert>:null
              }
              </div>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e)=>setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ?<VisibilityOff />:<Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                </FormControl>
                {
               error&& (error.code===2)?(
                <Alert severity="error">
                  <AlertTitle>{error.message}</AlertTitle>
                </Alert>
               ):null
              }
              <div className='w-full mt-3 mb-3'>
                <Button style={{width:'100%',backgroundColor:colorCodes.primary,color:'white',padding:'15px'}} onClick={signIn}>Sign in</Button>
              </div>
              <div className='w-full mt-3 mb-3 text-center'>
                <div className='w-full mt-3 mb-3 flex gap-1 justify-center'>
                  <Button startIcon={<FacebookIcon size={30}/>} variant="contained" style={{padding:'15px',flexBasis:'50%'}}>Facebook</Button>
                  <Button startIcon={<GoogleIcon size={30}/>} variant="contained" style={{padding:'15px',flexBasis:'50%'}}>Google</Button>
                </div>
                <div className='w-full mt-3 mb-3 text-center'>
                  <p className='text-center w-full'>
                    <NavLink
                      to="/forgot-password"
                    >
                      <Button style={{textTransform:"capitalize",color:'#505050'}}>Forgot password? Reset password</Button>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
         <div className='w-full mt-3 mb-3 text-center'>
                <p className='text-center w-full'>
                  <NavLink
                    to="/register"
                  >
                    <Button style={{textTransform:"capitalize",color:'#505050'}}>Don't have an account? Sign up</Button>
                  </NavLink>
                </p>
              </div>
        </DialogActions>
        <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      </BootstrapDialog>
  );
}
