import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {colorCodes} from '../data/dummy';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BackdropLoader from './BackdropLoader';
//
import Alert from '@mui/material/Alert';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ForgotPasswordDialogue(props) {
    const {setCurrentUser}= useStateContext();
 const [error,setError]= React.useState(null);
 const [email,setEmail]= React.useState(null);
 const [codeSent,setCodeSent]= React.useState(false);
 const navigate=useNavigate();
const resendCode=async()=>{
    if(email===null||email===""){
        setError({
            code:1,
            message:"Valid Email Required!"
        })
    }else{
      toggleBackdrop(1);
        // Send confirmation code to user's email
        Auth.forgotPassword(email)
        .then(data =>{
            //console.log(data);
            setCodeSent(true);
            setCurrentUser({username:email});
            navigate('/reset-password');
        })
        .catch(err =>{
          toggleBackdrop(1);
            setError({
                code:1,
                message:err.message
            })
            //console.log('error resending code: ', err);
        });
    }
    
}
/////custom backdrop loader
const [showBackdrop,setShowBackdrop]= React.useState(false);
const toggleBackdrop=(value)=>{
  switch (value) {
    case 1:
      setShowBackdrop(true);
    break;
    case 0:
      setShowBackdrop(false);
    break;
  
    default:
      setShowBackdrop(!showBackdrop);
      break;
  }
  
}
  return (
    <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.secondary}}>Enter E-mail Address</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className='text-center'>
            <div className='w-full'>
                <TextField
                required
                id="outlined-required"
                label="Email Address"
                defaultValue=""
                type="text"
                onChange={(e)=>setEmail(e.target.value)}
                autoFocus
                />
                {
                error&& (error.code===1||error.code===2)?<Alert severity="error">{error.message}</Alert>:null
              }
            </div>   
              <div className='w-full mt-3 mb-3'>
                <Button style={{width:'92%',margin:'0 4%',backgroundColor:colorCodes.secondary,color:'white'}} onClick={resendCode}>{codeSent?"Resend Code":"Send Code"}</Button>
              </div>
            </div>
          </Box>
        </DialogContent>
        <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      </BootstrapDialog>
  );
}
