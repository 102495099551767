import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import VideoPlayer from './VideoPlayer';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useStateContext } from '../contexts/ContextProvider';
function PaperComponent(props) {
    const {mobile} = useStateContext();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{width:mobile?'100%':'60%'}} />
    </Draggable>
  );
}

export default function PlayerDialogue(props) {
  const {currentScreenPlayerOptions} = useStateContext();
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ ml:1, p: 2,display:'flex',justifyContent:'space-between' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.toggleDialogue}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <BootstrapDialogTitle  id="customized-dialog-title" onClose={props.toggleDialogue}>
          <div className='text-center' style={{flexBasis:'80%'}}>{currentScreenPlayerOptions?.name}</div>
        </BootstrapDialogTitle>
        <DialogContent>
            <div className='w-full'>
                {currentScreenPlayerOptions?<VideoPlayer/>:null}
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
