import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import {colorCodes} from '../data/dummy';
import UploadDropBox from './UploadDropBox';
import Screen from './Screen';
import { useStateContext } from '../contexts/ContextProvider';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import WarningIcon from '@mui/icons-material/Warning';
import { Amplify, Storage } from 'aws-amplify';
import CampaignUploadProgress from './CampaignUploadProgress';
import CampaignProcessingProgress from './CampaignProcessingProgress';
import SavingCampaignProgress from './SavingCampaignProgress';
//
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

///
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CurrencyFormat from 'react-currency-format';
import SummaryScreen from './SummaryScreen';
import AWS from 'aws-sdk';
//
import * as uuid from 'uuid';
AWS.config.update({
  region: 'us-east-2',
  apiVersion: 'latest',
  credentials: {
    accessKeyId:"AKIAVGKKEX7675XW7CXU",
    secretAccessKey:"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"
  }
})
window.Buffer = window.Buffer || require("buffer").Buffer; 
//
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(category, selectedCategories, theme) {
  return {
    fontWeight:
    selectedCategories.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
///
const steps = ['Billboard Details', 'Billboard Image', 'Summary'];
///
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));
//
export default function Steps(props) {
  const rekognition = new AWS.Rekognition({apiVersion: '2016-06-27'});
  const s3 = new AWS.S3({apiVersion: '2006-03-01'});
  const {userAccount,campaignFile,setSuggestedScreens,suggestedScreens,countries,
    selectedScreens,allScreens,setAllScreens,places,setPlaces,mobile,currentLauchedCampaign,
     setCurrentLauchedCampaign} = useStateContext();
    const [campaignUploadProgress,setCampaignUploadProgress]= React.useState(0);
  const theme = useTheme();
  const [currentUploadingCampaignIdentifier,setCurrentUploadingCampaignIdentifier]= React.useState(null);
  const acceptedFileTypes=[
    'image/jpeg','image/png','image/gif','image/jpg',
    'video/mp4','video/webm','video/ogg'
  ];
  const[campaignUploadStage,setCampaignUploadStage]= React.useState({
    level:0,
    text: ''
  });
  const [uploadedCampaignURL,setUploadedCampaignURL]= React.useState(null);
  const handleChangeDuration=(event)=>{
    setCurrentLauchedCampaign({...currentLauchedCampaign,duration:event.target.value})
  }
  const handleTitleChange=(event)=>{
    setCurrentLauchedCampaign({...currentLauchedCampaign,name:event.target.value})
  }
  const handleDescriptionChange=(event)=>{
    setCurrentLauchedCampaign({...currentLauchedCampaign,description:event.target.value})
  }
  const handleCountryChange=(event)=>{
    setAllScreens(null);
    setCurrentLauchedCampaign({...currentLauchedCampaign,country:event.target.value});
    getScreens(event.target.value);
  }
  const handleChangeCity = (event) => {
    const {
      target: { value },
    } = event;
    // setCities(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    const newCities=[...currentLauchedCampaign.cities,...(typeof value === 'string' ? value.split(',') : value)];
    const uniqueCities=[...new Set(newCities)];
    setCurrentLauchedCampaign({...currentLauchedCampaign,cities:uniqueCities});
  };
  const handlePlaceChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPlaces(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    const newPlaces=[...currentLauchedCampaign.places,...( typeof value === 'string' ? value.split(',') : value)];
    const uniquePlaces=[...new Set(newPlaces)];
    setCurrentLauchedCampaign({...currentLauchedCampaign,places:uniquePlaces});
  };
  const handleChangeState=(event)=>{
    const {
      target: { value },
    } = event;
    // setPlaces(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    const newStates=[...currentLauchedCampaign.states,...( typeof value === 'string' ? value.split(',') : value)];
    const uniqueStates=[...new Set(newStates)];
    setCurrentLauchedCampaign({...currentLauchedCampaign,states:uniqueStates});
  };
  ///
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // console.log(activeStep)
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    // // check if all data is provided by user
    // if(activeStep===0){
    //   if(currentLauchedCampaign.name===null||currentLauchedCampaign.name===""){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid campaign name",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"You should provide a name for this campaign",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.country===null||currentLauchedCampaign.country===""){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid country",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"You should select a country to run this campaign",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.states===null||currentLauchedCampaign.states.length===0){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid states",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"You should select the states you want to run this campaign in",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.cities===null||currentLauchedCampaign.cities.length===0){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid cities",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"You should select the cities you want to run this campaign in",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.places===null||currentLauchedCampaign.length===0){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid places",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Select some places you would like to run your Ads in",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.duration===null||currentLauchedCampaign.duration<1||currentLauchedCampaign.duration>12){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid duration",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Provide the duration for which this campaign should run in months. The value should be a number between 1 and 12 inclusive.",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.description===null||currentLauchedCampaign.description===""){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid description",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Provide a description for this campaign",
    //       route:null,
    //       action:null,
    //     })
    //   }else{
    //     // display backdrop
    //     props.toggleBackdrop(1);
    //     let filteredScreens=[];
    //     // sort the screens array based on user info
    //     // loop through cities and compare
    //     currentLauchedCampaign?.cities?.forEach(city=>{
    //         allScreens.forEach(screen=>{
    //           if(screen.city.toLowerCase()===city.toLowerCase()){
    //             //push screen inside the filteredScreens but first check if screen is already in suggested screens
    //             if(filteredScreens.indexOf(screen)===-1)  filteredScreens.push(screen);
    //           }
    //       })
    //     })
    //     //loop through places and compare them in allScreens
    //     currentLauchedCampaign?.places?.forEach(place=>{
    //       allScreens.forEach(screen=>{
    //         if(screen.place.toLowerCase()===place.toLowerCase()){
    //           //push screen inside the filteredScreens but first check if screen is already in suggested screens
    //           if(filteredScreens.indexOf(screen)===-1)  filteredScreens.push(screen);
              
    //         }
    //       })
    //     })
    //     // push suggested screens to the suggested screens store
    //     if(filteredScreens.length>0) setSuggestedScreens([...filteredScreens]);
    //      // close backdrop
    //      props.toggleBackdrop(0);
    //     //move to next step
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped(newSkipped);
    //   }
    // }

    // if(activeStep===1){
    //   console.log(campaignFile);
    //   if(selectedScreens===null||selectedScreens.length<1){
    //     props.toggleCustomDialogue({
    //       heading:"No screens selected",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Select the screens you would like to advertise on",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(currentLauchedCampaign.price===null||currentLauchedCampaign.price<1){
    //     props.toggleCustomDialogue({
    //       heading:"No budget resolved for this campaign",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Select the screens you would like to advertise on in order to resolve the budget for this campaign",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(campaignFile.file===null){
    //     props.toggleCustomDialogue({
    //       heading:"No valid file selected",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Select a valid file for your advert",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(campaignFile.fileType===null||acceptedFileTypes.indexOf(campaignFile.fileType.toLowerCase())===-1){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid file type selected",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Invalid file type selected. only .jpg, .png, .jpeg, .gif, .webm, .mp4, .ogg, are allowed",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(campaignFile.fileSize===null||campaignFile.fileSize>20){
    //     props.toggleCustomDialogue({
    //       heading:"File too large",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Maximum file size allowed is 20 MB",
    //       route:null,
    //       action:null,
    //     })
    //   }else if(campaignFile.videoDuration&&campaignFile.videoDuration>35){
    //     props.toggleCustomDialogue({
    //       heading:"Invalid Duration",
    //       icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
    //       message:"Video duration longer than specified. Maximum video duration allowed is 35 secs",
    //       route:null,
    //       action:null,
    //     })
    //   }else{
    //     //move to next step
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped(newSkipped);
    //   }
    // }
    //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped(newSkipped);
    // if(activeStep===2){
    //   //move to next step
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   setSkipped(newSkipped);
    // }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  //store all cities for selected country
  const [cities, setCities] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [country, setCountry] = React.useState(null);
  async function getScreens(country){
    //show backdrop loader
    props.toggleBackdrop(1);
    const params = { crossDomain: true, withCredentials: true };
    const api = axios.create({ baseURL:"https://backend.digitadooh.com"});
    api
      .get("getScreens.php?country="+country,params)
      .then((result) => {
        let screens = result.data;
        if (screens.length > 0) {
          setAllScreens(screens);
          let allCities=[];
          let allStates=[];
          let allPlaces=[];
          //loop through each screen and get it's city
          screens.forEach((screen) => {
            //push all cities
            if(allCities.indexOf(screen.city)===-1) allCities.push(screen.city);
            //push all states
            if(allStates.indexOf(screen.state)===-1) allStates.push(screen.state);
            //push all places
            if(allPlaces.indexOf(screen.place)===-1) allPlaces.push(screen.place);
          })
          // set country meta data
          if(allCities.length>0){
            setCities(allCities);
            setStates(allStates);
            setCountry(screens[0].country);
            setPlaces([...allPlaces])
            props.toggleBackdrop(0);
          }

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //
  const renderStepBlock = (step) => {
    if(step===0){
        return(
          <div className='mt-5'>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <div className='text-center'>
                
                  <div className='w-full'>
                      <TextField
                      required
                      id="outlined-required"
                      label="Billboard name"
                      defaultValue=""
                      type="text"
                      style={{width:'80%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxlength={40}
                      value={currentLauchedCampaign.name}
                      onChange={handleTitleChange}
                      helperText="Give a short name to this billboard"
                      />
                  </div>
                  <div className='w-full'>
                    <FormControl sx={{ m: 1, width:'80%' }} size="small">
                        <InputLabel id="demo-select-small">Country</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={currentLauchedCampaign.country}
                          label="Country"
                          onChange={handleCountryChange}
                        >
                          {
                            countries.map((countryX,index)=>(
                              <MenuItem value={countryX} key={index}>{countryX}</MenuItem>
                            ))
                          }
                        </Select>
                        <FormHelperText>Select a country to advertise in</FormHelperText>
                      </FormControl>
                  </div>
                  <div className='w-full'>
                    <FormControl sx={{ m: 1,width:'80%'}}>
                    <InputLabel id="demo-select-small">Region</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={currentLauchedCampaign.states}
                        onChange={handleChangeState}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {states.map((state,index) => (
                          <MenuItem
                            key={index}
                            value={state}
                            style={getStyles(state,currentLauchedCampaign.states, theme)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Select states to advertise in</FormHelperText>
                    </FormControl>
                  </div>
                  <div className='w-full'>
                    <FormControl sx={{ m: 1,width:'80%'}}>
                      <InputLabel id="demo-multiple-name-label">City</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={currentLauchedCampaign.cities}
                        onChange={handleChangeCity}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {cities.map((city,index) => (
                          <MenuItem
                            key={index}
                            value={city}
                            style={getStyles(city,currentLauchedCampaign.cities, theme)}
                          >
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Select city</FormHelperText>
                    </FormControl>
                  </div>
                  <div className='w-full'>
                      <div className='w-full'>
                        <TextField
                        required
                        id="outlined-required"
                        label="Address"
                        defaultValue=""
                        type="text"
                        style={{width:'80%',justifyContent:'space-between'}}
                        onChange={handleChangeDuration}
                        value={currentLauchedCampaign.duration}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText="Enter billboard address"
                        />
                      </div>
                  </div>
                  <div className='w-full'>
                    <TextField
                      id="outlined-multiline-static"
                      label="Billboard description"
                      multiline
                      rows={4}
                      defaultValue=""
                      style={{width:'80%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleDescriptionChange}
                      value={currentLauchedCampaign.description}
                      helperText="Write billboard description"
                      
                    />
                  </div>
                </div>
              </Box>
          </div>
        )
    }
    if(step===1){
      return(
        <div className='w-full mt-5 text-center'>
            {/* <div className='w-full'>
              <div className='text-left m-auto'>
                <Button style={{textTransform:'capitalize'}}>Suggested Screens</Button>
              </div>
              <div className='flex justify-between gap-1 overflow-x-scroll overflow-y-hidden whitespace-nowrap'>
                {
                    suggestedScreens?.map((screen,index)=>(
                        <Screen key={index} data={screen} togglePlayer={props.togglePlayer}/>
                    ))
                }
              </div>
                <Button className='p-12 m-auto mt-3' style={{textTransform:'lowercase'}}>
                    {selectedScreens?selectedScreens.length+" "+'Screen(s) selected':"0 screens selected"}
                </Button>
                <div className='P-12 m-auto'>
                  <Button style={{textTransform:'capitalize',backgroundColor:colorCodes.secondary,color:'white'}} onClick={props.toggleScreenSuggestion}>Open screen suggestions</Button>
                </div>
            </div> */}
            {/* <div className='w-full'>
              <Button className='p-12 m-auto mt-3' style={{textTransform:'capitalize',color:colorCodes.secondary}} onClick={props.toggleScreenSuggestion}>
                  <h2>Total cost:{currentLauchedCampaign.price>0?<CurrencyFormat isNumericString={true} value={currentLauchedCampaign.price.toString()} displayType={'text'}  thousandSeparator={true} prefix={`${selectedScreens[0].currencySymbol}`} />:'0.00'}</h2>  
              </Button>
            </div>
            <div className='w-full'>
              <div className='text-left m-auto'>
                <Button style={{textTransform:'capitalize'}}>Want more screens?</Button>
              </div>
                <Button className='p-12 m-auto' 
                style={{backgroundColor:colorCodes.secondary,color:'white',width:'75%',textTransform:'capitalize'}}
                onClick={props.toggleScreenDialogue}
                >
                  Add more screens
                </Button>
            </div> */}
            {/* <div className='w-full'>
              <div className='text-left m-auto'>
                <Button style={{textTransform:'capitalize'}}>Preview screens</Button>
              </div>
                <Button className='p-12 m-auto' 
                style={{backgroundColor:colorCodes.primary,color:'white',width:'75%',textTransform:'capitalize'}}
                onClick={props.toggleSelectedDialogue}
                >
                  View selected screens 
                </Button>
            </div> */}
            <div className='w-full mt-5 text-left'>
              <div className='text-left m-auto mt-2 mb-2'>
                <Button style={{textTransform:'capitalize'}}>Upload Billboard Image</Button>
                {/* <p className='mt-3' style={{color:colorCodes.secondary}}>Max 20 secs allowed for videos</p> */}
              </div>
              <div className="m-auto">
                <UploadDropBox fileTypes={{'videos/*)':[]}} toggleError={props.toggleCustomDialogue}/>
              </div>
            </div>
        </div>
      )
    }
    if(step===2){
      return(
        <div className='w-full mt-5 text-left'>
             <Timeline  sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div>
                       <h1 className='font-bold'>Billboard summary</h1>
                       <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard name</h3>
                          <p class="font-sans text-b">
                              {
                                currentLauchedCampaign?.name
                              }
                          </p>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard country</h3>
                          <p class="font-sans text-b">
                              {
                                currentLauchedCampaign?.country
                              }
                          </p>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard State/Region</h3>
                          <div class="font-sans text-b">
                             <ul className='list-disc list-inside'>
                              {
                                  currentLauchedCampaign?.states.map(state =>(
                                    <li className='m-2' >{state}</li>
                                  ))
                                }
                             </ul>
                          </div>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard city/town</h3>
                          <div class="font-sans text-b">
                             <ul className='list-disc list-inside'>
                              {
                                  currentLauchedCampaign?.cities.map(city =>(
                                    <li className='m-2' >{city}</li>
                                  ))
                                }
                             </ul>
                          </div>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard address</h3>
                          <div class="font-sans text-b">
                             <ul className='list-disc list-inside'>
                              {
                                  currentLauchedCampaign?.places.map(place =>(
                                    <li className='m-2' >{place}</li>
                                  ))
                                }
                             </ul>
                          </div>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg  mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard description</h3>
                          <p class="font-sans text-b">
                            {
                              currentLauchedCampaign?.description
                            }
                          </p>
                        </div>
                        <div class="p-3 bg-white shadow rounded-lg mt-2">
                          <h3 class="text-sm border-b font-bold">Billboard price/month</h3>
                          <p class="font-sans font-bold text-xl" style={{color:colorCodes.secondary}}>
                            {
                              currentLauchedCampaign.price>0?<CurrencyFormat isNumericString={true} value={currentLauchedCampaign.price.toString()} displayType={'text'}  thousandSeparator={true} prefix={`${selectedScreens[0].currencySymbol}`} />:null
                            }
                          </p>
                        </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div>
                      <h2 className='font-bold'>Billboard image</h2>
                      <div  class="p-3 bg-white shadow rounded-lg mt-2">
                        {
                          campaignFile?(<div>
                            {
                                (campaignFile.showPreview)&&(campaignFile.mediaType==='IMAGE')?(<img src={campaignFile.previewUrl}/>):null
                            }
                            {
                              (campaignFile.showPreview)&&(campaignFile.mediaType==='VIDEO')?(<video className="w-full video" controls>
                                <source src={campaignFile.previewUrl}/>
                            </video>):null
                            }
                        </div>):null
                        }
                        
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
        </div>
      )
    }
  }
  const processCampaign=(e)=>{
    e.preventDefault();
    //storeCampaignDetails();
    //uploadCampaignFile();
    deployToCoreApp("405a4e0ae444be4162c3b59d5ddf6d2f.png");
  }
  const storeCampaignDetails=()=>{
    setCampaignUploadStage({
      level:1,
      text: 'Saving campaign details...'
    })
    const campaignData=JSON.stringify({
      name: currentLauchedCampaign.name,
      country:currentLauchedCampaign.country,
      states: currentLauchedCampaign.states,
      cities:currentLauchedCampaign.cities,
      places: currentLauchedCampaign.places,
      duration: currentLauchedCampaign.duration,
      description: currentLauchedCampaign.description,
      price: currentLauchedCampaign.price,
      selectedScreens:selectedScreens,
      suggestedScreens:suggestedScreens,
      file:campaignFile.file,
      fileType:campaignFile.fileType,
      fileSize:campaignFile.fileSize,
      fileName:campaignFile.fileName,
      mediaType:campaignFile.mediaType,
      videoDuration:campaignFile.videoDuration,
      user_id:1,
      identifier:uuid.v4()
    });
    let formData=new FormData();
    formData.append('campaign',campaignData);
    const params = { crossDomain: true, withCredentials: true };
    const api = axios.create({ baseURL:"https://backend.digitadooh.com"});
    api.post('create_campaign.php',formData)
    .then(response=>{
      if(response.data.status==1){
        //store the id of the campaign in a state variable
        setCurrentUploadingCampaignIdentifier(response.data.identifier);
        uploadCampaignFile();
      }
    })
    .catch(error=>{
      console.log(error);
    })

  }
  //methd to upload campaign file
  const uploadCampaignFile =async()=>{
    setCampaignUploadStage({
      level:1,
      text: 'Uploading campaign file...'
    })
    try{
      const result = await Storage.put(currentLauchedCampaign.name+"."+campaignFile.fileName,campaignFile.file,{
        progressCallback(progress) {
          //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          //setCampaignUploadProgress(progress.loaded);
        },
      });
      //console.log(result);
      const key=result.key;
      const fileUrl="https://digitadcampaignstore203553-dev.s3.amazonaws.com/public/"+key;
      console.log(fileUrl);
      setUploadedCampaignURL(fileUrl);
      // AMAZON rekognition check 
      uploadToCoreApp();
      //upload to core app
    }catch(error){
      //console.log(error);
      props.toggleCustomDialogue({
        heading:"Upload Error",
        icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
        message:"An error occurred while uploading the campaign file",
        route:null,
        action:null,
      })
    }
    
  }
  const deployToCoreApp =(fileName)=>{
    setCampaignUploadStage({
      level:1,
      text: 'Uploading Billboard...'
    })
    //////////////
    let piCredentials = {
      username: "pidigitadsignage",
      password: "Nelson@Akem@1000.",
    };
    ///
    var user = piCredentials.username;
    var password = piCredentials.password;
    var base64encodedData = Buffer.from(user + ":" + password).toString(
      "base64"
    );
    let digitadApps = {
      users: "https://app.digitadooh.com",
      piApp: "https://ourcore.digitadooh.com",
    };
    //
    //loop through each screen and get and get playlist info
    selectedScreens.forEach(screen=>{
      //console.log(screen.playlist);
      var config = {
        method: "get",
        url: digitadApps.piApp + "/api/playlists/"+screen.playlist,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Basic ${base64encodedData}`,
        },
      };
      axios(config)
      .then(response=>{
        const currentAsset={
          "filename":fileName,
          "duration":campaignFile.mediaType==='IMAGE'?null:campaignFile.duration,
          "isVideo":campaignFile.mediaType==='IMAGE'?false:true,
          "selected": true,
          "option": {
            "main": true,
            "side": true,
            "bottom": true,
            "zone4": true,
            "subduration": "Unknown Type: seconds",
            "bannerText":currentLauchedCampaign.name,
          },
          "fullscreen": true,
          "side": "string",
          "bottom": "string",
          "zone4": "string",
          "zone5": "string",
          "zone6": "string"
        }
        const playlistData=response.data.data;
        const newAssets=[...playlistData.assets,currentAsset];
        //update playlist to include new asset
        var config = {
          method: "post",
          url: digitadApps.piApp + "/api/playlists/"+screen.playlist,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Basic ${base64encodedData}`,
          },
          data:JSON.stringify({assets:newAssets})
        };
        axios(config)///
        .then(response=>{
          console.log(response.data);
        })
        .catch(error=>{
          console.log(error.message);
        })///////

      })
      .catch(error=>{
        console.log(error.message);
      })
    })

  }
  const uploadToCoreApp =async()=>{
    setCampaignUploadStage({
      level:1,
      text: 'Processing campaign file details...'
    })
    //UPLOAD to core app
    let piCredentials = {
      username: "pidigitadsignage",
      password: "Nelson@Akem@1000.",
    };
    let digitadApps = {
      users: "https://app.digitadooh.com",
      piApp: "https://ourcore.digitadooh.com",
    };
    const coreUser=process.env.PI_APP_USERNAME;
    const corePassword=process.env.PI_APP_PASSWORD;
    const coreURL=process.env.PI_APP_URL;
    let formData = new FormData();
    formData.append("assets",campaignFile.file);
    var user = piCredentials.username;
    var password = piCredentials.password;
    var base64encodedData = Buffer.from(user + ":" + password).toString(
      "base64"
    );
    //vonfig
    var config = {
      method: "post",
      url: digitadApps.piApp + "/api/files/",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Basic ${base64encodedData}`,
      },
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        //let percent = Math.floor((loaded * 100) / total);
        //setCampaignUploadProgress(loaded);
        //console.log(loaded+":working");
      },
      data: formData,
    };
    axios(config)
    .then(function(response){
        //check if campaign was successfully uploaded
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setCampaignUploadStage({
            level:1,
            text: 'Storing campaign file details...'
          })
          let file_name = response.data.data[0].name;
          let file_info = JSON.stringify({
            files: [
              {
                name:file_name,
                type: response.data.data[0].type,
                size: response.data.data[0].size,
              },
            ],
            categories: ["string"],
          });
          //store uploade file information in core database
          // let options = {
          //   onUploadProgress: (ProgressEvent) => {
          //     const { loaded, total } = ProgressEvent;
          //     let percent = Math.floor((loaded * 100) / total);
          //     setCampaignProgress(loaded);
          //   },
          // };
          var config2 = {
            method: "post",
            url: digitadApps.piApp + "/api/postupload/",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${base64encodedData}`,
            },
            data: file_info,
          };

          axios(config2)////start saving file information
          .then(function(response2) {
            console.log(response2.data.success);
            //check if campaign details was successfully stored in core database
            if (response2.data.success ===true) {
              //launch campaign
              setCampaignUploadStage({
                level:0,
                text: ''
              })
            }
              
          })
          .catch(error=>{
            console.log(error);
            props.toggleCustomDialogue({
              heading:"Failed to store uploaded file information",
              icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
              message:"An error occurred while storing your uploaded file information",
              route:null,
              action:null,
            })
          })////////end saving file information
        }
    })
    .catch(error=>{
      console.log(error);
      props.toggleCustomDialogue({
        heading:"Processing Error",
        icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
        message:"An error occurred while processing the campaign file. Please try again",
        route:null,
        action:null,
      })
    })////end
  }
  const renderStage=()=>{
    if(campaignUploadStage.level===1){
      return(
        <div className='text-center mt-3 mb-3'>
         <SavingCampaignProgress/>
         <p className='text-base' style={{color:colorCodes.secondary}}>{campaignUploadStage.text}</p>
        </div>
      )
    }

    if(campaignUploadStage.level===2){
      return(
        <div className='text-center mt-3 mb-3'>
          <CampaignUploadProgress campaignProgress={campaignUploadProgress}/>
          <p className='text-base' style={{color:colorCodes.secondary}}>{campaignUploadStage.text}</p>
        </div>
      )
    }

    if(campaignUploadStage.level===3){
      return(
        <div className='text-center mt-3 mb-3'>
            <CampaignProcessingProgress campaignProgress={campaignUploadProgress}/>
            <p className='text-base' style={{color:colorCodes.secondary}}>{campaignUploadStage.text}</p>
        </div>
      )
    }

  }
  return (
    <Box sx={{ width:mobile?'100%':'60%',border:'1px solid #ccc',padding:'10px' }}>
      <Stepper alternativeLabel activeStep={activeStep} style={{color:colorCodes.secondary}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps} color={colorCodes.secondary}>
              <StepLabel {...labelProps} ><p className='font-bold text-xl'>{label}</p></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <div>
            <Box sx={{}}>
              <div className='w-full text-left'>
                <div style={{width:'80%'}} className="m-auto mt-2 mb-2">
                  {
                    renderStage()
                  }
                </div>
                {/* <Button onClick={handleReset} style={{width:'100%',textTransform:'capitalize',margin:'10px 0'}}>Read and accept Digit-AD's campaign policies</Button> */}
                  <FormGroup style={{width:'80%'}} className="m-auto">
                    {/* <FormControlLabel
                      style={{width:'100%',margin:'10px auto',textTransform:'none'}}
                      className='m-auto'
                      control={<Android12Switch defaultChecked  checked={true}
                      />}
                      label="I have read and accepted DIGIT-AD campaign policies"
                    /> */}
                    <Button type="submit" onClick={processCampaign} style={{width:'80%',margin:'0 auto',backgroundColor:colorCodes.secondary,color:'white',textTransform:'capitalize'}}>Submit billboard</Button>
                  </FormGroup>
                </div>
              </Box>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form className='w-full' encType='multipart/form-data'>
              {
                renderStepBlock(activeStep)
              }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              style={{backgroundColor:colorCodes.secondary,color:'white',textTransform:'capitalize'}}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button  onClick={handleNext} style={{backgroundColor:colorCodes.secondary,color:'white',textTransform:'capitalize'}}>
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
          </form>
        </React.Fragment>
      )}
    </Box>
  );
}
