import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {colorCodes} from '../data/dummy';
import CloseIcon from '@mui/icons-material/Close';
import SellIcon from '@mui/icons-material/Sell';
import { useStateContext } from '../contexts/ContextProvider';
import CurrencyFormat from 'react-currency-format';
import PlaceIcon from '@mui/icons-material/Place';
import CategoryIcon from '@mui/icons-material/Category';
import Chip from '@mui/material/Chip';
import Groups2Icon from '@mui/icons-material/Groups2';
import BusinessIcon from '@mui/icons-material/Business';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SummaryScreen(props) {
  const {currentScreenPlayerOptions,setCurrentScreenPlayerOptions,currentLauchedCampaign,setCurrentLauchedCampaign,selectedScreens,setSelectedScreens,allScreens,setAllScreens} = useStateContext();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.data.img.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Box sx={{ maxWidth: 500, flexGrow: 1,border:'1px solid #eee',borderRadius:'4px',cursor:'pointer',display:'inline-block' }}>
      <Paper
        square
        elevation={0}
        sx={{
          height:'auto',
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <div className='flex flex-wrap gap-1 items-center p-2 justify-center'>
          <Chip
              icon={<BusinessIcon/>}
              label={props.data.name}
            />
          <Chip
              icon={<CategoryIcon style={{color:'white'}}/>}
              label={props.data.place}
              style={{backgroundColor:colorCodes.primary,color:'white'}}
            />
          <Chip
              icon={<Groups2Icon/>}
              label={props.data.population+" people daily"}
          />
            <Button
                  size="small"
                  onClick={handleNext}
                  style={{backgroundColor:colorCodes.secondary,color:'white',borderRadius:'20px'}}
                  className="flex items-center gap-1 justify-center"
                >
                <SellIcon/>
                {/* {<CurrencyFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} />} */}
                <CurrencyFormat isNumericString={true} value={props.data.price.toString()} displayType={'text'}  thousandSeparator={true} prefix={`${props.data.currencySymbol}`} />/Month
            </Button>
           <Chip
              icon={<PlaceIcon style={{color:'white'}}/>}
              label={props.data.location}
              style={{backgroundColor:colorCodes.secondary,color:'white'}}
            />
        </div>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props.data.img.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height:280,
                  display: 'block',
                  maxWidth:'100%',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step}
                alt="Image not available"
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
      />
    </Box>
  );
}

export default SummaryScreen;
