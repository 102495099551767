import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import {NavLink } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
export default function SideBarDrawer(props) {
const {activeMenu,rightMenu,setRightMenu, setActiveMenu, screenSize } = useStateContext();

  const [state, setState] = React.useState({
    top:false,
    left:activeMenu,
    bottom:false,
    right:rightMenu,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
    //close sidebar if active
    if(rightMenu&&activeMenu===false){
      rightMenu?setRightMenu(false):setRightMenu(true);
    }else if(rightMenu===false&&activeMenu===true){
      activeMenu? setActiveMenu(false):setActiveMenu(true);
    }
    
  };
////////////

const handleCloseSideBar = () => {
  if (activeMenu !== undefined && screenSize <= 900) {
    setActiveMenu(false);
  }
};

const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-l-full  text-white  text-md m-2';
const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-l-full text-md text-black dark:hover:text-black hover:bg-light-gray m-2';

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {
          <div className="mt-0 ">
            {
              /**<div className="flex justify-between items-center">
              <Link to="/" onClick={handleCloseSideBar} className="items-center  flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
                <img src={logo} className="rounded w-20" alt='logo'/>
                <button className='p-5 text-white rounded-l-full ml-3 shadow-md' style={{backgroundColor:colorCodes.primary}}><span>Akemshow</span></button>
              </Link>
            </div> */
            }
          {props.data.map((item) => (
            <div key={item.title}>
              <p className="text-black m-3 mt-4 font-sans">
                {item.title}
              </p>
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.route}`}
                  key={link.name}
                  onClick={handleCloseSideBar}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? "#1DA1F2": '',
                  })}
                  className={({ isActive }) => (isActive ? activeLink : normalLink)}
                >
                  {link.icon}
                  <span className="capitalize">{link.name}</span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
        }
      </List>
    </Box>
  );


  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
