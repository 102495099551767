import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {colorCodes} from '../data/dummy';
import BackdropLoader from './BackdropLoader';
//
import { Auth } from 'aws-amplify';
//
import {NavLink,useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ResetPasswordDialogue(props) {
const {user}= useStateContext();
 const [code,setCode]=React.useState(null);
 const [error,setError]= React.useState(null);
 const [showPassword, setShowPassword] = React.useState(false);
 const [new_password,setNew_password]= React.useState(null);
 const navigate=useNavigate();
 const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
 async function resetPassword() {
    let username=null;
    if(user){
        username=user.username;
    }else{
        username=(JSON.parse(localStorage.getItem("user"))).username;
    }
    // const localStorageUser=JSON.parse(localStorage.getItem("user"));
    // const newUser=user?user:localStorageUser;
    if(username){
      toggleBackdrop(1);
        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(username, code, new_password)
        .then(data =>{
            console.log(data);
            navigate('/login');
        })
        .catch(err =>{
          toggleBackdrop(0);
            setError({
                code:1,
                message:err.message
            })
        });
    }
}

const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  /////custom backdrop loader
const [showBackdrop,setShowBackdrop]= React.useState(false);
const toggleBackdrop=(value)=>{
  switch (value) {
    case 1:
      setShowBackdrop(true);
    break;
    case 0:
      setShowBackdrop(false);
    break;
  
    default:
      setShowBackdrop(!showBackdrop);
      break;
  }
  
}
  return (
    <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.secondary}}>Create a new password</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className='text-center'>
                <div className='w-full'>
                        <TextField
                        required
                        id="outlined-required"
                        label="Verification Code"
                        defaultValue=""
                        type="text"
                        onChange={(e)=>setCode(e.target.value)}
                        autoFocus
                        />
                </div>   
              <div className='w-full'>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={new_password}
                          onChange={(e)=>setNew_password(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ?<VisibilityOff />:<Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                </FormControl>
                   {
               error&& (error.code===1||error.code===2)?<Alert severity="error">{error.message}</Alert>:null
              }
              </div>
              <div className='w-full mt-3 mb-3'>
                <Button autoFocus style={{width:'92%',margin:'0 4%',backgroundColor:colorCodes.secondary,color:'white'}} onClick={resetPassword}>Reset Password</Button>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className='text-center w-full'>
          <p className='w-full'>
          <NavLink
                    to="/login"
                  >
                    <Button style={{textTransform:"capitalize"}}>Back</Button>
                  </NavLink>
          </p>
          </div>
        </DialogActions>
        <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      </BootstrapDialog>
  );
}
