import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useStateContext } from '../contexts/ContextProvider';
import {colorCodes} from '../data/dummy';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import NavigationIcon from '@mui/icons-material/Navigation';
import Fab from '@mui/material/Fab';
///
import CustomMessageDialogue from './CustomMessageDialogue';
import CreateBillboardDialogue from './CreateBillboardDialogue';
///
import AdUnitsIcon from '@mui/icons-material/AdUnits';
export default function Nav() {
    //////
    const {setSmallDisplay,activeMenu,rightMenu,setRightMenu,setMobile, setActiveMenu, setScreenSize, screenSize } = useStateContext();

    useEffect(() => {
      const handleResize = () => {
        setScreenSize(window.innerWidth);
        console.log(activeMenu);
      };
     
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      if (screenSize <= 1000) {
        setActiveMenu(false);
      } else {
        setActiveMenu(false);
      }
      if(screenSize<=1000){
        setMobile(true);
      }else{
        setMobile(false);
      }
      if (screenSize <= 350) {
        setSmallDisplay(true);
      }
    }, [screenSize]);
  
    const handleActiveMenu = () => setActiveMenu(!activeMenu);
    //////
  const [anchorEl2, setAnchorEl2] = React.useState(null);

///
  const open = Boolean(anchorEl2);
  const toggleRightDrawer=()=>{
    setRightMenu(!rightMenu);
  }

  const [openLoginDialogue, setOpenLoginDialogue] = React.useState(true);
  /// create billboard dialogue
  const [openBillboardDialogue, setOpenBillboardDialogue] = React.useState(false);
  const toggleBillboardDialogue=()=>{
    setOpenBillboardDialogue(!openBillboardDialogue);
  }
  /////custom message dialogue
  const [customMessageDialogue,setCustomMessageDialogue]= React.useState(false);
  const [customDialogueData,setCustomDialogueData]= React.useState(null);
  const toggleCustomMessageDialogue=(data)=>{
    setOpenLoginDialogue(false);
    setCustomDialogueData(data);
    setCustomMessageDialogue(!customMessageDialogue);
  }

  //reset password dialogue
  return (
    <div className='mt-0'>
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{backgroundColor:colorCodes.secondary,zIndex:-1}}>
        <Toolbar>
          
          <Button startIcon={<MenuIcon size={30}/>} onClick={handleActiveMenu} style={{color:'white',margin:'0 0.5rem'}}>
            Menu
          </Button>
          <Box sx={{ display: { xs: 'none', md: 'flex' },gap:'2rem' }}>
           
           
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex',flexDirection:'right', alignItems: 'center', textAlign: 'center',margin:'0 0.5rem' }}>
            <Fab variant="extended" onClick={toggleBillboardDialogue} style={{textTransform:'capitalize'}}>
              <AdUnitsIcon sx={{ mr: 1 }} />
              Add A Screen
            </Fab>
            {
              /**<IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              style={{margin:'0 2rem'}}
            >
              <Badge badgeContent={17} color="error" >
                <NotificationsIcon />
              </Badge>
            </IconButton> */
            }
            <Tooltip title="Account settings">
              <IconButton
                onClick={toggleRightDrawer}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                style={{color:'white'}}
              >
                {/*<Avatar sx={{ width: 32, height: 32 }}>M</Avatar>*/}
                <AccountCircle />
              </IconButton>
          </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    
      
    <CreateBillboardDialogue open={openBillboardDialogue} toggleDialogue={toggleBillboardDialogue}/>
    <CustomMessageDialogue open={customMessageDialogue} toggleDialogue={toggleCustomMessageDialogue} data={customDialogueData}/>
    </div>
  );
}
