import React, { useEffect} from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import {Footer} from '../components';
import NavigationBar from '../components/NavigationBar';
import LandingPageSideBar from '../components/LandingPageSideBar';
import Campaign from '../components/Campaign';
import Billboard from '../components/Billboard';
const  AvailableBillboards = () => {
  const {mobile} = useStateContext();
  useEffect(() => {
    
  }, []);
  
  return (
    <div className="flex relative dark:bg-main-dark-bg">
    {mobile? (
    <div className="w-0 dark:bg-secondary-dark-bg">
        <LandingPageSideBar/>
    </div>
    ) : (
      <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
      <LandingPageSideBar/>
    </div>
    )}
    <div
      className={
        mobile
          ? 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2'
          : 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full'
      }
    >
      <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
        <NavigationBar/>
      </div>
      <div className={mobile?"mt-20 p-2":"p-10"}>
        <div>
          <p>Available Billboards</p>
          <div className={mobile?"flex flex-wrap flex-row items-center justify-evenly mt-2":"flex flex-wrap flex-row gap-y-5 mt-5 gap-1"}>
            {
              [1,2,3,4,5,6,7,8,9,10].map((billboard,index)=>(
                <Billboard/>
              ))
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
  );
};

export default AvailableBillboards;