import React from 'react';
import { rightBarLinks,leftBarLinks } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import SideBarDrawer from './SideBarDrawer';
import LandingPageLeftBar from './LandingPageLeftBar';
const LandingPageSideBar = () => {
  const {activeMenu, mobile,rightMenu } = useStateContext();

const renderSideBar=()=>{
    //renser side bar on large screens but render drawer on mobile
    if(activeMenu&&mobile){
        return(<SideBarDrawer data={leftBarLinks} title="Home Of Live Events"/>)
    }
    if(!mobile){
        return(<LandingPageLeftBar data={leftBarLinks} title="Home Of Live Events"/>)
    }
}
  return (
    <div className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      { renderSideBar()}
      {
        rightMenu? <SideBarDrawer data={rightBarLinks} title="Account Settings"/>:null
      }
    </div>
  );
};

export default LandingPageSideBar;