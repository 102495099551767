import React,{useEffect} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useStateContext } from '../contexts/ContextProvider';
import {colorCodes} from '../data/dummy';
import CurrencyFormat from 'react-currency-format';
import SummaryScreen from './SummaryScreen';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));
export default function PreviewedCampaignDetails() {
    const {previewedCampaign} = useStateContext();
    const [progressWidth,setProgressWidth] = React.useState(0);
    useEffect(() => {
        console.log(previewedCampaign);
        if(previewedCampaign.status==1&&previewedCampaign.expired==0&&previewedCampaign.deployed==1&&previewedCampaign.paid==1){
            const progress=(parseInt(previewedCampaign.daysSpent)/(parseInt(previewedCampaign.duration)*30))*100;
            console.log(progress);
            setProgressWidth(progress);
        }
        //expired campaign
        else if(previewedCampaign.daysSpent>=previewedCampaign.duration){
            setProgressWidth(100);
        }
      }, []);
    function countProgress(){
        let displayText=""; 
        //campaign is deployed and paid
        if(previewedCampaign.status==1&&previewedCampaign.expired==0&&previewedCampaign.deployed==1&&previewedCampaign.paid==1){
            displayText=previewedCampaign.daysSpent+" / "+(parseInt(previewedCampaign.duration)*30)+" days spent";
            //calculate progress
           
            return displayText;

        }
        //expired campaign
        else if(previewedCampaign.daysSpent>=previewedCampaign.duration){
            displayText=(parseInt(previewedCampaign.duration)*30)+" / "+(parseInt(previewedCampaign.duration)*30)+" days spent.  Campaign Completed!";
            return displayText;

        }
        //insufficient funds
        else if(previewedCampaign.status==1&&previewedCampaign.deployed==0&&previewedCampaign.paid==0){
            displayText="Insufficient funds";
            return displayText;

        }
        // rejected
        else if(previewedCampaign.status==3){
            displayText="Campaign rejected!";
            return displayText;

        }
        else if(previewedCampaign.status==0&&previewedCampaign.deployed==0&&previewedCampaign.paid==0){
            displayText="Pending Approval!...";
            return displayText;

        }


    }
  return (
    <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
         <TimelineItem>
            <TimelineSeparator>
            <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
            <div>
                <h2 className='font-bold'>Campaign progress</h2>
                <div  class="p-3 bg-white shadow rounded-lg mt-2">
                    <BorderLinearProgress variant="determinate" value={progressWidth} />
                    <p>{countProgress()}</p>
                </div>
            </div>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
            <div>
                <h1 className='font-bold'>Campaign details</h1>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Campaign name</h3>
                    <p class="font-sans text-b">
                        {
                        previewedCampaign?.name
                        }
                    </p>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Campaign country</h3>
                    <p class="font-sans text-b">
                        {
                        previewedCampaign?.country
                        }
                    </p>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Choice states/regions</h3>
                    <div class="font-sans text-b">
                        <ul className='list-disc list-inside'>
                        {
                            previewedCampaign?.states.map(state =>(
                            <li className='m-2' >{state}</li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Choice cities/towns</h3>
                    <div class="font-sans text-b">
                        <ul className='list-disc list-inside'>
                        {
                            previewedCampaign?.cities.map(city =>(
                            <li className='m-2' >{city}</li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Choice of places</h3>
                    <div class="font-sans text-b">
                        <ul className='list-disc list-inside'>
                        {
                            previewedCampaign?.places.map(place =>(
                            <li className='m-2' >{place}</li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Campaign duration</h3>
                    <p class="font-sans text-b" style={{color:colorCodes.primary}}>
                    {
                        previewedCampaign?.duration+" month(s)"
                    }
                    </p>
                </div>
                <div class="p-3 bg-white shadow rounded-lg  mt-2">
                    <h3 class="text-sm border-b font-bold">Campaign description</h3>
                    <p class="font-sans text-b">
                    {
                        previewedCampaign?.description
                    }
                    </p>
                </div>
                <div class="p-3 bg-white shadow rounded-lg mt-2">
                    <h3 class="text-sm border-b font-bold">Campaign budget</h3>
                    <p class="font-sans font-bold text-xl" style={{color:colorCodes.secondary}}>
                    {
                        previewedCampaign.budget>0?<CurrencyFormat isNumericString={true} value={previewedCampaign.budget.toString()} displayType={'text'}  thousandSeparator={true} prefix={`${previewedCampaign.selectedScreens[0].currencySymbol}`} />:null
                    }
                    </p>
                </div>
            </div>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
            <div>
                <h2 className='font-bold'>Campaign screens</h2>
                <div class="p-3 bg-white shadow rounded-lg mt-2">
                <h3 class="text-sm border-b font-bold">Number of screens</h3>
                <p class="font-sans text-b">
                    {
                    previewedCampaign.selectedScreens?.length+" screen(s) selected"
                    }
                </p>
                </div>
                <div  class="p-3 bg-white shadow rounded-lg mt-2">
                    <h3 class="text-sm border-b font-bold">Screens</h3>
                    <div className='flex justify-between gap-1 overflow-x-scroll overflow-y-hidden whitespace-nowrap'>
                    {
                        previewedCampaign.selectedScreens?.map((screen,index)=>(
                            <SummaryScreen key={index} data={screen}/>
                        ))
                    }
                    </div>
                </div>
            </div>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
            <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
            <div>
                <h2 className='font-bold'>Campaign media file</h2>
                <div  class="p-3 bg-white shadow rounded-lg mt-2">
                {
                    previewedCampaign.url?(<div>
                    {
                        (previewedCampaign.mediaType==='IMAGE')?(<img src={"https://digitadcampaignstore100518-dev.s3.us-east-2.amazonaws.com/public/"+previewedCampaign.url}/>):(<video className="w-full video" controls>
                        <source src={"https://digitadcampaignstore100518-dev.s3.us-east-2.amazonaws.com/public/"+previewedCampaign.url}/>
                    </video>)
                    }
                    
                </div>):null
                }
                
                </div>
            </div>
            </TimelineContent>
        </TimelineItem>
    </Timeline>
  );
}