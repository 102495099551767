import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import VideoJS from './VideoJS';
import videojs from 'video.js';

export default function VideoPlayer() {
  const {currentScreenPlayerOptions} = useStateContext();
  /**video-js settings */
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src:currentScreenPlayerOptions.video,
      type:'video/mp4'
    }]
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <div className='w-full'>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />   
    </div>
  );
}
