import React from 'react';
import ReactDOM from 'react-dom';

import './css/index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);