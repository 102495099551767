import React,{ useEffect,useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {colorCodes} from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PreviewedCampaignDetails from './PreviewedCampaignDetails';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewCampaign(props) {
  const {mobile} = useStateContext();
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.toggleDialogue}
        TransitionComponent={Transition}
        style={{marginTop:mobile?'50px':0}}
      >
        <AppBar sx={{ position: 'relative',backgroundColor:colorCodes.secondary}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.toggleDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <div className='text-center'>Reviewing Campaign</div>
            </Typography>
            <Button autoFocus color="inherit" onClick={props.toggleDialogue}>
              Exit
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{backgroundColor:'linen'}} className={mobile?"w-full p-5 border-2":"w-3/5 p-10 m-auto border-2 border-gray-200 mt-5"}>
           <PreviewedCampaignDetails/>
        </div>
          
      </Dialog>
      
    </div>
  );
}
