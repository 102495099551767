import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useStateContext } from '../contexts/ContextProvider';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {colorCodes} from '../data/dummy';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
function PaperComponent(props) {
    const {mobile} = useStateContext();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{width:mobile?'100%':'30%'}} />
    </Draggable>
  );
}

export default function PaymentOptionsDialogue(props) {
  const {paymentMethods} = useStateContext();
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ ml:1, p: 2,display:'flex',justifyContent:'space-between' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.toggleDialogue}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{width: '100%'}}
      >
        <BootstrapDialogTitle  id="customized-dialog-title" onClose={props.toggleDialogue}>
          <div className='text-center' style={{flexBasis:'80%'}}>Payment Options</div>
        </BootstrapDialogTitle>
        <DialogContent>
            <div className='w-full'>
                <Button
                    size="large"
                    style={{backgroundColor:colorCodes.secondary,color:'white',margin:'5px auto'}}
                    className="w-full flex items-center p-6 pointer justify-between items-center"
                    >
                    <Chip
                        avatar={<Avatar alt="PAY WITH PAYSTACK" src="https://digitad-media-files.s3.us-east-2.amazonaws.com/paystack.png" />}
                        label="PAY WITH PAYSTACK"
                        variant="outlined"
                        style={{color:'white',cursor:'pointer',padding:'10px'}}
                        className='pointer'
                        size="large"
                    />
                </Button>
                <Button
                    size="large"
                    style={{backgroundColor:colorCodes.primary,color:'white',margin:'5px auto'}}
                    className="w-full mt-2 flex items-center p-6 justify-between items-center"
                    >
                    <Chip
                        avatar={<Avatar alt="Pay with MTN MOMO" src="https://digitad-media-files.s3.us-east-2.amazonaws.com/momo.png" />}
                        label="PAY WITH MTN MOMO"
                        variant="outlined"
                        style={{color:'white',cursor:'pointer',padding:'10px'}}
                    />
                </Button>
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
