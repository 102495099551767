import React,{useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import { useStateContext } from '../contexts/ContextProvider';
import WarningIcon from '@mui/icons-material/Warning';
import {colorCodes} from '../data/dummy';
export default function UploadDialogue(props) {
    const {campaignFile,setCampaignFile} = useStateContext();
    const [showPreview,setShowPreview]= React.useState(false);
    const [previewUrl,setPreviewUrl] = React.useState(null);
    const [mediaType,setMediaType]= React.useState(null);
    const video= document.querySelector(".video");
    const onDrop=useCallback((acceptedFiles)=>{
        if(acceptedFiles.length > 0){
            const file =acceptedFiles[0];
            const fileType = file.type.toLowerCase();
            const filename=file.name;
            //convert filesize to mb
            const fileSize=(file.size / (1024 * 1024)).toFixed(2);
            //check file type
            if (
            fileType === "video/mp4" ||
            fileType === "video/webm" ||
            fileType ==="video/ogg" ||
            fileType ==="image/jpeg" ||
            fileType ==="image/png" ||
            fileType ==="image/jpg" ||
            fileType ==="image/gif" 
            ) {
                //check file size
                if(fileSize <=10){
                    let mediaType = null;
                    if
                    (fileType === "video/mp4" ||
                    fileType === "video/webm" ||
                    fileType ==="video/ogg")
                    {
                        mediaType ="VIDEO";
                    }
                    if(fileType ==="image/jpeg" ||
                    fileType ==="image/png" ||
                    fileType ==="image/jpg" ||
                    fileType ==="image/gif")
                    {
                        mediaType = 'IMAGE';
                    }
                    const previewMedia = URL.createObjectURL(file);
                    setPreviewUrl(previewMedia);
                    setMediaType(mediaType);
                    //SET VIDEO Duration
                    let videoDuration=mediaType==="VIDEO"?video.duration:null;
                    //throw an error if duration is longer than specified
                    if(mediaType==="VIDEO"&&videoDuration>35){
                        // throw an error
                        props.toggleError({
                            heading:"Invalid Duration",
                            icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
                            message:"Video duration longer than specified. Maximum video duration allowed is 35 secs",
                            route:null,
                            action:null,
                        })
                    }
                    setCampaignFile({file:file,fileType:fileType,fileName:filename,fileSize:fileSize,previewUrl:previewMedia,mediaType:mediaType,showPreview:true,videoDuration:videoDuration});
                    setShowPreview(true);
                    console.log(campaignFile);
                }else{
                    //display error message
                    props.toggleError({
                        heading:"File too large",
                        icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
                        message:"File too large the maximum file size allowed is 10 MB",
                        route:null,
                        action:null,
                    })
                }
            }else{
                //display an error message
                props.toggleError({
                    heading:"Invalid file type",
                    icon:<WarningIcon style={{color:colorCodes.primary,width:'10rem',height:'10rem'}} size={24}/>,
                    message:"Invalid file type selected. only .jpg, .png, .jpeg, .gif, .webm, .mp4, .ogg, are allowed",
                    route:null,
                    action:null,
                })
            }
        }

    },[]);
    const {getRootProps,getInputProps,isDragActive}=useDropzone({
        onDrop,
        accept:props.fileTypes
    })
  return (
    <paper 
    sx={{
    '&:hover':{border:'1px solid #ccc'}
    }}
    >
        <div style={{padding:'10px',border:'1px dashed #ccc',background:'#fafafa',cursor:'pointer',color:'#bdbdbd'}} {...getRootProps()} className="mt-2">
        <input {...getInputProps()}/>
        {
            isDragActive?(
                <p style={{color:'green'}}>Drop the files here...</p>
            ):(<p>Drag 'n' Drop some files  here, or click to select files</p>)
        }
        <em>(images with *.jpeg, *.jpg, *.png extension will be accepted, videos with *.webm, *.mp4, *.ogg extension will be accepted)</em>
        </div>
        <div>
            {
                campaignFile?(<div>
                    {
                            (campaignFile?.showPreview||showPreview)&&(mediaType==='IMAGE'||campaignFile?.mediaType==='IMAGE')?(<img src={previewUrl||mediaType==='IMAGE'||campaignFile?.previewUrl}/>):null
                        }
                        {
                        (campaignFile?.showPreview||showPreview)&&(mediaType==='VIDEO'||campaignFile?.mediaType==='VIDEO')?(<video className="w-full video" controls>
                            <source src={previewUrl||campaignFile?.previewUrl}/>
                        </video>):null
                    }
                </div>): null
            }
            
        </div>
        
    </paper>
  );
}
