import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CurrencyFormat from 'react-currency-format';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export default function Billboard() {

  return (
    <div>
        <Card sx={{ maxWidth: 300 }}>
            <CardContent>
                <img
                    src="https://images.unsplash.com/photo-1599803654935-5b9d1c93578c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                    srcSet={`https://images.unsplash.com/photo-1599803654935-5b9d1c93578c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt="billboard image"
                    loading="lazy"
                />
                <CardHeader
                    avatar={
                    <LocationOnIcon size="30" />
                    }
                    title="Screen Location"
                    subheader="Glacier modern Bonamousadi"
                />
                <CardHeader
                    avatar={
                    <LocalOfferIcon size="30" />
                    }
                    title="Screen Price"
                    subheader="XAF32,000/month"
                />
            </CardContent>
            
        </Card>
    </div>
  );
}