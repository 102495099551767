import React, { useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/Signup';
import EmailVerify from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import TransactionStory from './pages/TransactionHistory';
import AvailableBillboards from './pages/AvailableBillboards';
import ActiveAds from './pages/ActiveAdverts';
import Campaigns from './pages/Campaigns';
import BookedCampaigns from './pages/BookedCampaigns';
import './css/App.css';
//

import { useStateContext } from './contexts/ContextProvider';
import { Auth } from 'aws-amplify';
import axios from 'axios';
const App = () => {
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  const {setTransactions,setUserAccount,archivedCampaigns,setArchivedCampaigns,activeCampaigns,setActiveCampaigns,pendingCampaigns,setPendingCampaigns,setUserId,setUserInfo,isLoged,setIsLoged, setCurrentColor, setCurrentMode, currentMode} = useStateContext();
  //console.log(activeMenu);
    useEffect(() => {
      console.log("privilege"+process.env.USER_PRIVILEGE);
      const currentThemeColor = localStorage.getItem('colorMode');
      const currentThemeMode = localStorage.getItem('themeMode');
      if (currentThemeColor && currentThemeMode) {
        setCurrentColor(currentThemeColor);
        setCurrentMode(currentThemeMode);
      }
    }, []);
  
    useEffect(() => {
      setIsLoged(true);
      async function getUserInfo(){
         const user = await Auth.currentAuthenticatedUser();
         const {attributes}=user;
         const userName=user.username;
         //console.log(userName);
         //console.log(attributes['custom:companyName']);
         console.log(attributes);
         if(attributes){
          const balance=attributes['custom:balance']>0?attributes['custom:balance']:0;
          //console.log(balance);
           setIsLoged(true);
           setUserInfo(attributes);
           const info={
            balance:balance,
            companyName:attributes['custom:companyName'],
            email:attributes.email,
            companySize:attributes['custom:companySize'],
            name:attributes.name,
            country:attributes['custom:country'],
            firstName:attributes['custom:firstName'],
            lastName:attributes['custom:lastName'],
            'address':attributes.address,
            'city':attributes['custom:city'],
            'picture':attributes.picture
           }
           setUserAccount(info);
           //console.log(info);
           setUserId(userName);
         }
      }
      //if(!isLoged) getUserInfo();
      fetch_campaigns();
      getTransactions();
     }, []);
     function getTransactions(){
      const api = axios.create({ baseURL:"https://backend.digitadooh.com"});
      api.get('admin_fetch_transactions.php')
      .then(response=>{
        console.log(response.data);
        setTransactions(response.data)
      })
      .catch(error=>{
        console.log(error.message);
      })
     }
     function fetch_campaigns(){
      const api = axios.create({ baseURL:"https://backend.digitadooh.com"});
      api.get('admin_fetch_campaigns.php')
      .then(response=>{
        if(response.data){
          console.log(response.data);
          let activeCamps=[];
          let pendingCamps=[];
          let archivedCamps=[];
          response.data.forEach(data=>{
            if(data.status==1&&data.paid==1){
              //console.log(data);
              activeCamps.push(data);
              //console.log(data);
            }else if(data.status==0){
              pendingCamps.push(data);
            }else if(data.status==2){
              archivedCamps.push(data);
            }
          });
          if(activeCampaigns.length<=0){
            setActiveCampaigns(activeCamps);
          }
          if(pendingCampaigns.length<=0){
            setPendingCampaigns(pendingCamps);
          }
          if(archivedCampaigns.length<=0){
            setArchivedCampaigns(archivedCamps);
          }
        }
      })
    }
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Routes>
          {
            isLoged?(
              <>
                <Route path="/" element={<Home/>}/>
                <Route path="/dashboard" element={<Home/>}/>
                <Route path="/booked-campaigns" element={<BookedCampaigns/>}/>
                <Route path="/active-campaigns" element={<ActiveAds/>}/>
              </>
            ):(
              <>
                <Route path="/" element={<SignIn/>} />
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/register" element={<SignUp/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/verify-email" element={<EmailVerify/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
              </>
            )
          }
          
          {/*
          <Route path="/expired-ads" element={<ExpiredAds/>}/> */}
          <Route path="/transaction-history" element={<TransactionStory/>}/>
          <Route path="/available-screens" element={<AvailableBillboards/>}/>
          {/* <Route path="/booked-billboards" element={<BookedBillboards/>}/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;