import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {colorCodes} from '../data/dummy';
//
import {NavLink } from 'react-router-dom';
//
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomMessageDialogue(props) {
  return (
    <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.primary}}>{props.data&&props.data.heading?props.data.heading:null}</div>
        </BootstrapDialogTitle>
        <DialogContent dividers={props.data&&props.data.heading?true:null}>
          <div className='text-center'>
                <div>
                    <Button>
                        {
                            props.data&&props.data.icon?props.data.icon:null
                        }
                    </Button>
                </div>
                <div>
                    <p>{props.data&&props.data.message}</p>
                </div>
          </div>
        </DialogContent>
        <DialogActions>
            {
                props.data&&props.data.route?(
                    <NavLink
                  to={`/${props.data.route}`}
                >
                  <Button style={{textTransform:"capitalize",color:'white',backgroundColor:colorCodes.primary}}>{props.data.action}</Button>
                </NavLink>
                ):(<Button style={{textTransform:"capitalize",color:colorCodes.primary,}} onClick={props.toggleDialogue}>Close</Button>)
            }
        </DialogActions>
      </BootstrapDialog>
  );
}
