import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {colorCodes} from '../data/dummy';
//
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { companySizes } from '../data/dummy';
import { useNavigate} from 'react-router-dom';
//
import { CountryDropdown} from 'react-country-region-selector';
//
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import Alert from '@mui/material/Alert';
import {NavLink } from 'react-router-dom';

import BackdropLoader from './BackdropLoader';
//
import { Auth } from 'aws-amplify';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(size, sizes, theme) {
  return {
    fontWeight:
    sizes.indexOf(size) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
//
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function RegisterDialogue(props) {
  const {setCurrentUser}= useStateContext();
  const [country,setCountry]= React.useState(null);
  const [firstName,setFirstName]= React.useState(null);
  const [lastName,setLastName]= React.useState(null);
  const [email,setEmail]= React.useState(null);
  const [password,setPassword]= React.useState(null);
  const [companyName,setCompanyName]= React.useState(null);
  const [companySize,setCompanySize]= React.useState(null);
  const [error,setError]= React.useState(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const theme = useTheme();
  const [selectedSize, setSelectedSize] = React.useState([]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSize(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setCompanySize(value);
  };
  const handleSignup=()=>{
    if(firstName===null||firstName===""){
      setError({
        code:1,
        message:"First Name Required"
      })
    }else if(lastName===null||lastName===""){
      setError({
        code:2,
        message:"Last Name Required"
      })
    }else if(companyName===null||companyName===""){
      setError({
        code:3,
        message:"Company Name Required"
      })
    }else if(companySize===null||companySize===""){
      setError({
        code:4,
        message:"Company Size Required"
      })
    }else if(country===null||country===""){
      setError({
        code:5,
        message:"Country Required"
      })
    }else if(email===null||email===""){
      setError({
        code:6,
        message:"Email Required"
      })
    }else if(password===null||password===""){
      setError({
        code:7,
        message:"Password Required"
      })
    }else{
      setError(null);
      signUp();
    }
  }
  async function signUp() {
    toggleBackdrop(1);
    try {
        const { user } = await Auth.signUp({
            username:email,
            password,
            attributes: {
                email,
                name:firstName+" "+lastName,
                //other custom attributes 
                'custom:firstName':firstName,
                'custom:lastName':lastName,
                'custom:companyName':companyName,
                'custom:companySize':companySize,
                'custom:country':country,
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        });
        //console.log(user);
        //store user in state
        setCurrentUser(user)
        //Toggle email verification page
        navigate('/verify-email');
    } catch (err) {
      toggleBackdrop(0);
        //console.log('error signing up:', err);
        setError({
          code:7,
          message:err.message
        })
    }
}
/////custom backdrop loader
const [showBackdrop,setShowBackdrop]= React.useState(false);
const toggleBackdrop=(value)=>{
  switch (value) {
    case 1:
      setShowBackdrop(true);
    break;
    case 0:
      setShowBackdrop(false);
    break;
  
    default:
      setShowBackdrop(!showBackdrop);
      break;
  }
  
}
  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.primary}}><h1 className='text-4xl'>Join DIGIT-AD</h1></div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {width: '25ch',textAlign: 'center' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className='text-center'>
                <div className='w-full mt-3 mb-3'>
                   <TextField
                  required
                  id="outlined-required"
                  label="Full names"
                  defaultValue=""
                  type="text"
                  style={{width:'100%'}}
                  onChange={(e)=>setFirstName(e.target.value)}
                  autoFocus
                  />
                </div>
              {
               error&& (error.code===1)?<Alert severity="error">{error.message}</Alert>:null
              }
              <div className='mt-3 mb-3' style={{width:'100%'}}>
                   <CountryDropdown
                    value={country}
                    onChange={(val) => setCountry(val)} className="resize-none text-center w-full border-1 h-14" style={{borderColor:'#ccc',borderRadius:'4px'}}/>
                        {/**<RegionDropdown
                    country={country}
                    value={region}
                    onChange={(val) => this.selectRegion(val)} /> */}
                  
              </div>
              {
               error&& (error.code===2)?<Alert severity="error">{error.message}</Alert>:null
              }
              <div style={{width:'100%'}} className="mt-3 mb-3">
                <TextField
                    required
                    id="outlined-required"
                    label="E-mail"
                    defaultValue=""
                    type="email"
                    style={{width:'100%'}}
                    onChange={(e)=>setEmail(e.target.value)}
                    />
              </div>
              {
               error&& (error.code===3)?<Alert severity="error">{error.message}</Alert>:null
              }
              <div className='w-full mt-3 mb-3'>
                      <FormControl sx={{width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e)=>setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ?<VisibilityOff />:<Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                </FormControl>
                {
                error&& (error.code===4)?<Alert severity="error">{error.message}</Alert>:null
              }
              </div>
              <div className='w-full mt-3 mb-3'>
                <Button  style={{width:'100%',margin:'0 auto',backgroundColor:colorCodes.primary,color:'white',padding:'15px'}} onClick={handleSignup}>Sign up</Button>
              </div>
              <div className='w-full mt-3 mb-3 flex gap-1 justify-center'>
                <Button startIcon={<FacebookIcon size={30}/>} variant="contained" style={{padding:'15px',flexBasis:'50%'}}>Facebook</Button>
                <Button startIcon={<GoogleIcon size={30}/>} variant="contained" style={{padding:'15px',flexBasis:'50%'}}>Google</Button>
              </div>
              <div className='w-full mt-3 mb-3 text-center'>
                <p className='text-center w-full'>
                  <NavLink
                    to="/login"
                  >
                    <Button style={{textTransform:"capitalize",color:'#505050'}}>Already have an account? Login</Button>
                  </NavLink>
                </p>
              </div>
              
            </div>
          </Box>
        </DialogContent>
        <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      </BootstrapDialog>
    </>
  );
}
