import React from 'react';
import {AiOutlineHome} from 'react-icons/ai';
import { MdOutlineDashboard,MdSubscriptions} from 'react-icons/md';
import ArchiveIcon from '@mui/icons-material/Archive';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import HistoryIcon from '@mui/icons-material/History';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
export const colorCodes={
  primary:'#15907b',
  secondary:'#e36f21'
}


export const leftBarLinks = [
  {
    title: 'Smart Outdoor Advertising',
    links: [
      {
        name: 'Dashboard',
        icon: <MdOutlineDashboard size={24}/>,
        route:'dashboard',
      },
      // {
      //   name: 'Expired Adverts',
      //   icon: <AirplanemodeInactiveIcon size={24}/>,
      //   route:'expired-ads'
      // },
      {
        name: 'Booked Campaigns',
        icon: <BookmarkAddedIcon size={24}/>,
        route:'booked-campaigns'
      },
      {
        name: 'Active Campaigns',
        icon: <AirplanemodeActiveIcon size={24}/>,
        route:'active-campaigns'
      },
      {
        name: 'Available Screens',
        icon: <AdUnitsIcon size={24}/>,
        route:'available-screens'
      },
      {
        name: 'Transaction History',
        icon: <HistoryIcon size={24}/>,
        route:'transaction-history'
      },
    ],
  },

  // {
  //   title: 'Marketing & Design',
  //   links: [
  //     {
  //       name: '',
  //       icon: <MdSubscriptions size={24}/>,
  //       route:'marketing-agencies'
  //     },
  //   ],
  // },

];
export const rightBarLinks = [
  {
    title: 'Account Settings',
    links: [
      {
        name: 'Home',
        icon: <AiOutlineHome size={24}/>,
        route:''
      },
     
    ],
  },

  {
    title: 'SUBSCRIPTIONS',
    links: [
      {
        name: 'Subscription',
        icon: <MdSubscriptions size={24}/>,
        route:'subscription'
      },
    ],
  },

];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const companySizes= [
  '1-10',
  '11-100',
  '101-500',
  '500+',
];
export const countries=[
  'Cameroon',
  ''
]
export const screens=[
  {
      selected: true,
      label: 'San Francisco – Oakland Bay Bridge, United States',
      price:"N1200",
      currency: 'USD',
      img:[
          'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
          'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
          'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
          'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
          
      ]
},
{
  selected:false,
  label: 'San Francisco – Oakland Bay Bridge, United States',
  price:"N1200",
  currency: 'USD',
  img:[
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
      
  ]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected:false,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
{
selected: true,
label: 'San Francisco – Oakland Bay Bridge, United States',
price:"N1200",
currency: 'USD',
img:[
  'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  
]
},
]