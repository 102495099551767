import React from 'react'
import {NavLink,Link } from 'react-router-dom';
import logo from '../data/logo.png';
import { useStateContext } from '../contexts/ContextProvider';
import {colorCodes} from '../data/dummy';
function LandingPageLeftBar(props) {
    const {activeMenu,setActiveMenu, screenSize } = useStateContext();
    const handleCloseSideBar = () => {
        if (activeMenu !== undefined && screenSize <= 900) {
          setActiveMenu(false);
        }
      };
      
      const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-l-full  text-white  text-md m-2';
      const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-l-full text-md text-black dark:hover:text-black hover:bg-light-gray m-2';
      
  return (
    <div className="mt-0 ">
            <div className="flex justify-between items-center">
              <Link to="/" onClick={handleCloseSideBar} className="items-center p-3  flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
                <img src={logo} className="rounded w-40" alt='logo'/>
              </Link>
            </div>
          {props.data.map((item) => (
            <div key={item.title}>
              <p className="text-black m-3 mt-4 font-sans">
                {item.title}
              </p>
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.route}`}
                  key={link.name}
                  onClick={handleCloseSideBar}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ?colorCodes.secondary: '',
                  })}
                  className={({ isActive }) => (isActive ? activeLink : normalLink)}
                >
                  {link.icon}
                  <span className="capitalize">{link.name}</span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
  )
}

export default LandingPageLeftBar