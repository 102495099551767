import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyFormat from 'react-currency-format';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DashboardAccountInfo() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="flex justify-between mt-2">
        {/* <Card sx={{ maxWidth: 500 }}>
            <CardHeader
                avatar={
                <MonetizationOnIcon size="30" />
                }
                title="Month Income"
                subheader="Income generated this month"
            />
            <CardContent>
                <div className="text-xl text-center">
                    <CurrencyFormat isNumericString={true} value={5000000} displayType={'text'}  thousandSeparator={true} prefix={`XAF`} />
                </div>
                <Typography variant="body2" color="text.secondary">
                    This impressive paella is a perfect party dish and a fun meal to cook
                    together with your guests. Add 1 cup of frozen peas along with the mussels,
                    if you like.
                </Typography>
            </CardContent>
            
        </Card> */}
        <Card sx={{ maxWidth: 400 }}>
        <CardHeader
            avatar={
                <AccountBalanceWalletIcon size="30" />
            }
            title="Available Balance"
            subheader="Amount of withdrawable balance"
        />
        <CardContent>
                <div className="text-xl text-center">
                    <CurrencyFormat isNumericString={true} value={5000000} displayType={'text'}  thousandSeparator={true} prefix={`XAF`} />
                </div>
            <Typography variant="body2" color="text.secondary">
            This impressive paella is a perfect party dish and a fun meal to cook
            together with your guests. Add 1 cup of frozen peas along with the mussels,
            if you like.
            </Typography>
        </CardContent>
        
        </Card>
    </div>
  );
}