import React, { useEffect} from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import LoginDialogue from '../components/LoginDialogue';
const  SignIn = () => {
  const {mobile} = useStateContext();
  useEffect(() => {
    
  }, []);
  const [openLoginDialogue, setOpenLoginDialogue] = React.useState(true);
  /////login dialogue
    const toggleLoginDialogue=()=>{
      setOpenLoginDialogue(!openLoginDialogue);
    }
  return (
    <div className="w-full min-h-screen bg-fixed bg-clip-content bg-contain" style={{background:'url(https://images.unsplash.com/photo-1528402671825-9a525ab8b5b9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80)'}} >
         <LoginDialogue open={openLoginDialogue} toggleDialogue={toggleLoginDialogue} />
    </div>
  );
};

export default SignIn;