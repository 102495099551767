import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Step from './Stepper';
import {colorCodes} from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import ScreensDialogue from './ScreensDialogue';
import SelectedScreensDialogue from './SelectedScreensDialogue';
import SuggestedScreensDialogue from './SuggestedScreensDialogue';
import CustomMessageDialogue from './CustomMessageDialogue';
import BackdropLoader from './BackdropLoader';
import PlayerDialogue from './PlayerDialogue';
import PaymentOptionsDialogue from './PaymentOptionsDialogue';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateBillboardDialogue(props) {
  const {mobile} = useStateContext();
  const [openUploadDialogue, setOpenUploadDialogue] = React.useState(false);

  const toggleUploadDialogue=()=>{
    //close login dialogue
    setOpenUploadDialogue(!openUploadDialogue);
  }
  //screens dialogue
  const [openScreenDialogue,setOpenScreensDialogue ]= React.useState(false);
  const toggleScreenDialogue=()=>{
    setOpenScreensDialogue(!openScreenDialogue);
  }
  //selected screens dialogue
  const [openSelectedScreenDialogue,setOpenSelectedScreensDialogue ]= React.useState(false);
  const toggleSelectedScreenDialogue=()=>{
    setOpenSelectedScreensDialogue(!openSelectedScreenDialogue);
  }
   //suggested screens dialogue
   const [openSuggestedScreenDialogue,setOpenSuggestedScreensDialogue ]= React.useState(false);
   const toggleSuggestedScreenDialogue=()=>{
     setOpenSuggestedScreensDialogue(!openSuggestedScreenDialogue);
   }
     /////custom message dialogue
  const [customMessageDialogue,setCustomMessageDialogue]= React.useState(false);
  const [customDialogueData,setCustomDialogueData]= React.useState(null);
  const toggleCustomMessageDialogue=(data)=>{
    setCustomMessageDialogue(!customMessageDialogue);
    setCustomDialogueData(data);
  }
  /////custom backdrop loader
  const [showBackdrop,setShowBackdrop]= React.useState(false);
  const toggleBackdrop=(value)=>{
    switch (value) {
      case 1:
        setShowBackdrop(true);
      break;
      case 0:
        setShowBackdrop(false);
      break;
    
      default:
        setShowBackdrop(!showBackdrop);
        break;
    }
    
  }
   /////custom message dialogue
   const [playerDialogue,setShowPlayerDialogue]= React.useState(false);
   const togglePlayerDialogue=()=>{
    setShowPlayerDialogue(!playerDialogue);
   }
   /////custom message dialogue
   const [paymentOptionsDialogue,setPaymentOptionsDialogue]= React.useState(false);
   const togglePaymentOptionsDialogue=()=>{
    setPaymentOptionsDialogue(!paymentOptionsDialogue);
   }
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.toggleDialogue}
        TransitionComponent={Transition}
        style={{marginTop:mobile?'50px':0}}
      >
        <AppBar sx={{ position: 'relative',backgroundColor:colorCodes.secondary}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.toggleDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <div className='text-center'>Create A Billboard</div>
            </Typography>
            <Button autoFocus color="inherit" onClick={props.toggleDialogue}>
              Exit
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{backgroundColor:'linen'}} className={mobile?"w-full p-5":"w-4/5 p-10 m-auto flex justify-center"}>
          <Step toggleUploadDialogue={toggleUploadDialogue} toggleScreenDialogue={toggleScreenDialogue} toggleSelectedDialogue={toggleSelectedScreenDialogue}
           toggleScreenSuggestion={toggleSuggestedScreenDialogue}
            toggleCustomDialogue={toggleCustomMessageDialogue}
             toggleBackdrop={toggleBackdrop} togglePlayer={togglePlayerDialogue} togglePaymentOptions={togglePaymentOptionsDialogue}/>
        </div>
          
      </Dialog>
      <ScreensDialogue open={openScreenDialogue} toggleDialogue={toggleScreenDialogue} togglePlayer={togglePlayerDialogue}/>
      <SelectedScreensDialogue open={openSelectedScreenDialogue} toggleDialogue={toggleSelectedScreenDialogue} togglePlayer={togglePlayerDialogue}/>
      <SuggestedScreensDialogue open={openSuggestedScreenDialogue} toggleDialogue={toggleSuggestedScreenDialogue} togglePlayer={togglePlayerDialogue}/>
      <CustomMessageDialogue open={customMessageDialogue} toggleDialogue={toggleCustomMessageDialogue} data={customDialogueData}/>
      <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      <PlayerDialogue open={playerDialogue} toggleDialogue={togglePlayerDialogue}/>
      <PaymentOptionsDialogue open={paymentOptionsDialogue} toggleDialogue={togglePaymentOptionsDialogue}/>
    </div>
  );
}
