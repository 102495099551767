import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {colorCodes} from '../data/dummy';
import BackdropLoader from './BackdropLoader';
//
import { Auth } from 'aws-amplify';
//
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function VerifyEmail(props) {
const {user}= useStateContext();
 const [code,setCode]=React.useState(null);
 const [error,setError]= React.useState(null);
 const [email,setEmail]= React.useState(null);
 const [resend,setResend]= React.useState(false);
 const navigate=useNavigate();
 async function confirmSignUp() {
    let username=null;
    if(user){
        username=user.username;
    }else{
        username=email;
    }
    // const localStorageUser=JSON.parse(localStorage.getItem("user"));
    // const newUser=user?user:localStorageUser;
    if(username){
      toggleBackdrop(1);
        try {
            await Auth.confirmSignUp(username, code);
            navigate('/');
          } catch (err) {
            toggleBackdrop(0);
              console.log('error confirming sign up', err);
              setError({
                  code:1,
                  message:err.message
              })
          }
    }
}
const resendCode=async()=>{
    let username=null;
    if(user){
        username=user.username;
    }else{
        username=email;
    }
    // const localStorageUser=JSON.parse(localStorage.getItem("user"));
    // const newUser=user?user:localStorageUser;
    if(username){
      toggleBackdrop(1);
        try {
            await Auth.resendSignUp(username);
            setResend(true);
            setCode(null);
            console.log('code resent successfully');
        } catch (err) {
          toggleBackdrop(0);
            setError({
                code:1,
                message:err.message
            })
            console.log('error resending code: ', err);
        }
    }
}
/////custom backdrop loader
const [showBackdrop,setShowBackdrop]= React.useState(false);
const toggleBackdrop=(value)=>{
  switch (value) {
    case 1:
      setShowBackdrop(true);
    break;
    case 0:
      setShowBackdrop(false);
    break;
  
    default:
      setShowBackdrop(!showBackdrop);
      break;
  }
  
}
  return (
    <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
         <div className='text-center font-bold' style={{color:colorCodes.secondary}}>Confirm E-mail Address</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className='text-center'>
            {
                //show email field if email doesn't exist
                user?null:(
                    <div className='w-full'>
                        <TextField
                        required
                        id="outlined-required"
                        label="Email Address"
                        defaultValue=""
                        type="text"
                        onChange={(e)=>setEmail(e.target.value)}
                        autoFocus
                        />
                </div>                )
            }
              <div className='w-full'>
                  <TextField
                  required
                  id="outlined-required"
                  label="Verification Code"
                  defaultValue=""
                  type="text"
                  onChange={(e)=>setCode(e.target.value)}
                  autoFocus
                  />
                   {
               error&& (error.code===1||error.code===2)?<Alert severity="error">{error.message}</Alert>:null
              }
              </div>
              <div className='w-full mt-3 mb-3'>
                <Button autoFocus style={{width:'92%',margin:'0 4%',backgroundColor:colorCodes.secondary,color:'white'}} onClick={confirmSignUp}>Confirm Code</Button>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className='text-center w-full'>
            {resend?(
                <div className='mb-2 mt-2'>
                    <Alert severity="success">code resent successfully!</Alert>
                </div>
            ):<Button style={{textTransform:"capitalize"}} onClick={resendCode}>Re-send Code</Button>}
            
          </div>
        </DialogActions>
        <BackdropLoader show={showBackdrop} toggle={toggleBackdrop}/>
      </BootstrapDialog>
  );
}
