import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useStateContext } from '../contexts/ContextProvider';
export default function CampaignUploadProgress(props) {
  //const [progress, setProgress] = React.useState(0);
  const [campaignProgress]= useStateContext();
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       progressRef.current();
//     }, 500);

//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="buffer" value={props.campaignProgress} valueBuffer={props.campaignProgress+Math.random() * 10*2} />
    </Box>
  );
}